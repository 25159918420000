










import { Vue, Component, Prop } from 'vue-property-decorator';

import Loader from '@/shared/resources/components/Loader.vue';

@Component({
  components: {
    Loader,
  },
})
export default class Details extends Vue {
  /**
   * Props
   */
  @Prop() private loading!: boolean;

  /**
   * Decide to display details
   */
  private get displayDetails(): boolean {
    return !this.loading;
  }

  /**
   * Decide to display loader
   */
  private get displayLoader(): boolean {
    return this.loading;
  }
}
